<template>
  <div>
    <!-- Title -->
    <h3
      v-t="'login.reset_password'"
      class="mt-4 mb-2"
    />
    <!-- Form -->
    <v-form
      ref="form-reset-password"
      class="mb-8"
      @submit.prevent="onSubmit"
    >
      <label
        v-t="'login.new_password'"
        for="field-password"
        class="label-input"
      />
      <v-text-field
        id="field-password"
        v-model="password"
        :append-icon="show1 ? 'visibility_off' : 'visibility'"
        :type="show1 ? 'text' : 'password'"
        :rules="[rules.required, rules.password]"
        hide-details="auto"
        color="primary"
        name="password"
        class="mb-4"
        outlined
        dense
        @click:append="show1 = !show1"
      />
      <label
        v-t="'login.confirm_password'"
        for="field-password-confirmation"
        class="label-input"
      />
      <v-text-field
        id="field-password-confirmation"
        v-model="passwordConfirmation"
        :append-icon="show1 ? 'visibility_off' : 'visibility'"
        :type="show1 ? 'text' : 'password'"
        :rules="[rules.required, rules.passwordConfirmation]"
        hide-details="auto"
        color="primary"
        name="password-confirmation"
        class="mb-4"
        outlined
        dense
        @click:append="show1 = !show1"
      />
      <!-- Action -->
      <div class="d-flex align-center justify-space-between">
        <router-link
          to="/login"
          class="text-decoration-none"
        >
          <v-icon
            color="primary"
            v-text="'chevron_left'"
          />
          {{ $t(`${$vuetify.breakpoint.smAndUp === true ? 'login.back_to_login' : 'login.back'}`) }}
        </router-link>
        <v-btn
          color="primary"
          type="submit"
        >
          {{ $t('login.submit') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import login from '../../mixins/login';

export default {
  name: 'FormForgotPassword',
  mixins: [login],
  data () {
    return {
      password: '',
      passwordConfirmation: '',
      show1: false,
    };
  },
  metaInfo () {
    return {
      title: `${this.$t('login.reset_password')} | `,
    };
  },
  mounted () {
    if (!this.$route.query.token) {
      this.$router.push('/login');
    }
  },
  methods: {
    /**
     * Handle submit action + email API
     *
     * @returns {Void}
     */
    async onSubmit () {
      const { token } = this.$route.query;
      const { password, passwordConfirmation } = this;
      const validation = this.$refs['form-reset-password'].validate();
      if (validation === true) {
        try {
          const { apiRoot } = this.$xms.config;
          const postData = {
            password,
            passwordConfirmation,
            token,
          };
          await this.$http.post(`${apiRoot}/reset-password`, postData);
          this.$router.push('/login');
          this.$reportSuccess({ message: 'dialogs.reset_password_success' });
        } catch (e) {
          // If address inputed does not exist in database
          this.$reportError({ message: 'errors.reset_password_error' });
        }
      }
    },
  },
};
</script>

